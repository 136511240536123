import React, { useState, useEffect } from "react";
import { compose } from "recompose";
import { withStyles } from "@material-ui/core";
//import "../../../utils/styles/Mire.css";
//import "../../../utils/styles/header.css";
import QRCode from "react-qr-code";
import HiaFooter from "../../../widgets/HiaFooter";
import { Grid } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import DialogContent from "@material-ui/core/DialogContent";
import "./index.css";

const styles = (theme) => ({
  actions: {
    justifyContent: "center",
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
});

const Component = ({ actions, sessionInformation }) => {
  const [sessionId, setSessionId] = useState("");
  const [qrCodeData, setQrCodeData] = useState("");
  const [pollingInterval, setPollingInterval] = useState(null);
  const [sessionExpired, setSessionExpired] = useState(false);
  const [deviceType, setDeviceType] = useState("");
  const [waitingSession, setWaitingSession] = useState(false);
  const [showQrCode, setShowQrCode] = useState(false);
  const clientId = process.env.REACT_APP_CLIENT_ID;


   // Détection du type d'appareil
   const detectDeviceType = () => {
    const userAgent = navigator.userAgent.toLowerCase();
    if (/mobi|android|tablet|ipad|iphone/.test(userAgent)) {
      setDeviceType("mobile");
      return "mobile";
    }
    setDeviceType("desktop");
    return "desktop";
  };
   const showTheQrCode = () => {
    setShowQrCode(!showQrCode);
    setWaitingSession(!waitingSession);
  };


  // Generate a unique session ID
  const generateSessionId = () => {
    const newSessionId = `${clientId}-${Math.random().toString(36).substring(2, 11)}`;
    setSessionId(newSessionId);
    return newSessionId;
  };

  // Création de session et comportement selon l'appareil
  const signIn = async () => {
    if (deviceType === "desktop") {
      setShowQrCode(true);
      setWaitingSession(true);
      
      // Expiration de la session après 5 minutes
      setTimeout(() => {
        setSessionExpired(true);
        clearInterval(pollingInterval);
      }, 300000); 
    } else {
      // Cas mobile/tablette : Appeler l'API backend directement
      try {
        setWaitingSession(true);
        // Rediriger vers l'application mobile via un deeplink
        window.location.href = `https://app.hiasecure.com/sessions/?sessionId=${sessionId}&serviceName=showroom`;
      } catch (error) {
        console.error("Erreur lors de la connexion mobile :", error);
      }
    }
  };

  // Polling to check if an account is mapped to the session
  const getSessionInfo = async () => {
    if (!sessionId) return;
    /*
    actions.getSessionDetails(sessionId).then((session) => {
      console.log("session response :", session);
      if (session.data.login) {
        console.log("login with :", session.data.login);
        actions.validateLogin(session.data.login, "");
        setSessionInformation(session.data);
        clearInterval(pollingInterval); // Stop polling once session is found
      }
    });
    */
    actions.getSessionDetails(sessionId);
  };

  useEffect(() => {
    if (!deviceType) {
      detectDeviceType(); 
    }

    if (!sessionId) {
      generateSessionId();
    }
  }, [deviceType, sessionId]);


  useEffect(() => {
    if (sessionId) {
      const newQrCodeData = `https://app.hiasecure.com/sessions/?sessionId=${sessionId}&serviceName=showroom`;
      setQrCodeData(newQrCodeData);
    }
  }, [sessionId]);


  useEffect(() => {
    if (waitingSession && !sessionInformation) {
      console.log("Starting polling for session info...");
      const interval = setInterval(getSessionInfo, 5000); // Poll every 5 seconds
      setPollingInterval(interval);
      return () => clearInterval(interval); // Cleanup on component unmount
    }
  
    // Si la session a expiré, on nettoie
    if (sessionExpired) {
      clearInterval(pollingInterval);
    }
  }, [ sessionInformation , waitingSession, sessionExpired]); // Ajout des dépendances pertinentes

  return (
    <div id="mire">
      <div className="mireContainer">
        <p className="mireTitle">Sign in</p>
        <Grid item xs={12}>
          <DialogContent>
            <Grid container direction="column" alignItems="center" justify="center">
              {sessionExpired ? (
                <div>
                  <h3>Session expired. Please try again.</h3>
                </div>
              ) : deviceType === "mobile" ? (
                <>
                  <Button onClick={signIn} type="submit" color="primary">
                    Sign in
                  </Button>
                  {showQrCode && qrCodeData && (
                    <div style={{ textAlign: "center" }}>
                      <h3>Scan the QR Code to authenticate</h3>
                      <QRCode value={qrCodeData}
                      size={window.innerWidth < 600 ? 200 : 300} />
                    </div>
                  )}
                  {!showQrCode ? (
                    <p 
                      onClick={() => showTheQrCode()} 
                      style={{ textAlign: "center", marginTop: "10px" }} // Ajouter de l'espace entre le bouton et le texte
                    >
                      Afficher le QR code
                    </p>
                  ) : (
                    <p 
                      onClick={() => showTheQrCode()} 
                      style={{ textAlign: "center", marginTop: "10px" }} // Ajouter de l'espace entre le bouton et le texte
                    >
                      Enlever le QR code
                    </p>
                  )}
                </>
              ) : (
                <>
                  {!showQrCode ? (
                    <Button onClick={signIn} type="submit" color="primary">
                      Sign in
                    </Button>
                  ) : (
                    showQrCode && (
                      <div style={{ textAlign: "center" }}>
                        <h3>Scan the QR Code to authenticate</h3>
                        <QRCode value={qrCodeData} />
                      </div>
                    )
                  )}
                </>
              )}
            </Grid>


          </DialogContent>
        </Grid>
        <HiaFooter />
      </div>
    </div>
  );
};

export const enhance = compose(withStyles(styles));
export default enhance(Component);
